/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from "react";
import AppHome from "./tabs/Home";
import AppInvoices from "./tabs/Invoices";
import AppTracking from "./tabs/Tracking";
import AppClients from "./tabs/Clients";
import AppMore from "./tabs/More";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import HomeIcon from "./components/icons/HomeIcon";
import InvoiceIcon from "./components/icons/InvoiceIcon";
import TrackingIcon from "./components/icons/TrackingIcon";
import ClientIcon from "./components/icons/ClientIcon";
import MoreIcon from "./components/icons/MoreIcon";
import AppContext from "../AppContext";

const MainHome = () => {

  const context: any = useContext(AppContext);

  //first user name aftr /app/... if not found #... else use home
  const [activeTab, setActiveTab] = useState(
    window.location.pathname.split("/")[2] ||
      window.location.hash.replace("#", "") ||
      "home"
  );

  const tabs = [
    { name: "home", component: <AppHome /> },
    { name: "invoices", component: <AppInvoices /> },
    { name: "tracking", component: <AppTracking /> },
    { name: "clients", component: <AppClients /> },
    { name: "more", component: <AppMore /> },
  ];

  useEffect(() => {
    //if user role is customers_manager and activeTab is home, redirect to clients
    // console.log(window.location.pathname.split("/")[1]);
    if (
      context.user.role == "customers_manager" &&
      activeTab == "home" && window.location.pathname.split("/")[1] == "app") {
      setActiveTab("clients");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  return (
    <>
      <div className="flex h-screen bg-gray-50">
        <div className="flex flex-col gap-0 w-full">
          <main className="h-screen overflow-y-auto pb-[90px] w-full">
            {/* <!-- Remove everything INSIDE this div to a really blank page --> */}
            <div className="w-full">
              {tabs.map((tab) => (
                <div
                  key={tab.name}
                  className={`${activeTab === tab.name ? "" : "hidden"}`}
                >
                  {tab.component}
                </div>
              ))}
            </div>
          </main>
          <div className="z-40 fixed bottom-0 left-0 w-full bg-white pb-5 !pt-3">
            <BottomNavigation
              showLabels
              value={activeTab}
              onChange={(event, newValue) => {
                setActiveTab(newValue);
                //replace browser url by replace and append #home
                window.history.replaceState(
                  null,
                  "Home",
                  window.location.pathname + "#" + newValue
                );
              }}
            >
              {
                !['customers_manager'].includes(context.user?.role) && (
              <BottomNavigationAction
                value="home"
                label="Home"
                icon={<HomeIcon />}
                className={`z-40 ${
                  activeTab == "home" ? "!text-black" : "!text-gray-400"
                }`}
              />
                )
              }
              {
                !['customers_manager'].includes(context.user?.role) && (
              <BottomNavigationAction
                value="invoices"
                label="Invoices"
                icon={<InvoiceIcon />}
                className={`z-40 ${
                  activeTab == "invoices" ? "!text-black" : "!text-gray-400"
                }`}
              />
              )
            }
            {
                !['customers_manager'].includes(context.user?.role) && (
              <BottomNavigationAction
                value="tracking"
                label="Tracking"
                icon={<TrackingIcon />}
                className={`z-40 ${
                  activeTab == "tracking" ? "!text-black" : "!text-gray-400"
                }`}
              />
              )
            }
              <BottomNavigationAction
                value="clients"
                label="Clients"
                icon={<ClientIcon />}
                className={`z-40 ${
                  activeTab == "clients" ? "!text-black" : "!text-gray-400"
                }`}
              />
              <BottomNavigationAction
                value="more"
                label="More"
                icon={<MoreIcon />}
                className={`z-40 ${
                  activeTab == "more" ? "!text-black" : "!text-gray-400"
                }`}
              />
            </BottomNavigation>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainHome;
