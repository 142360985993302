
import { Button, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useState } from "react";

type Props = {
    show: boolean;
    setShow: (show: boolean) => void;
    onAddToCart: (product: any, quantity: number) => void;
};
export const CustomProductDialog = ({ show, setShow, onAddToCart }: Props) => {

    const [product, setProduct] = useState({
        type: "custom",
    } as any);

    const onInputChange = (e: any) => {
        const { name, value } = e.target;
        setProduct((prev: any) => ({ ...prev, [name]: value }));
    }

    const onSubmit = (e: any) => {
        e.preventDefault();
        setShow(false);
        onAddToCart(product, product.quantity);
    }

  return (
    <>
      <div
        className={`fixed left-0 top-0 h-screen z-50 bg-[#000000c0] w-full flex items-center justify-center ${
          !show && "hidden"
        }`}
        onClick={() => {
        //   setShow(false);
        }}
      >
        <IconButton
          aria-label="cart"
          color="primary"
          className="!absolute !top-0 !right-0 !m-3 !bg-white"
          onClick={() => {
            setShow(false);
          }}
        >
          <Close className="!text-black" />
        </IconButton>


          <div
            onClick={(e) => {
                e.stopPropagation();
            }}
            className="flex flex-col justify-center items-center gap-y-3 text-sm text-gray-700 bg-white rounded-md w-full mx-5 sm:w-auto sm:mx-auto p-4"
          >

            <form className="space-y-4 w-full" onSubmit={onSubmit} onChange={onInputChange}>

                <div className="w-full">
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                        Product Name:
                    </label>
                    <input
                        name="name"
                        required
                        type="text"
                        id="name"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm font-bold rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black"
                        placeholder="Product Name"
                        defaultValue=""
                    />
                </div>

                <div className="w-full">
                    <label
                        htmlFor="description"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Descripton:
                    </label>
                    <textarea
                        name="description"
                        rows={4}
                        required
                        id="description"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black"
                        placeholder="About product"
                        defaultValue={""}
                    />
                </div>

                <div className="w-full">
                    <label htmlFor="status" className="block text-sm font-medium text-gray-700">
                        Quantity:
                    </label>
                    <input
                        name="quantity"
                        required
                        min={0}
                        type="text"
                        placeholder="Quantity"
                        id="quantity"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm font-bold rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black"
                        defaultValue=""
                        data-listener-added_9a54c053="true"
                    />
                </div>
                
                <div className="w-full">
                    <label
                        htmlFor="price"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Total Price:
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <span className="text-gray-500 lg:text-sm font-bold">₦</span>
                        </div>
                        <input
                        name="price"
                        required
                        min={0}
                        type="number"
                        id="price"
                        className="bg-gray-50 border p-2.5 pl-6 font-bold border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-black dark:focus:border-black"
                        placeholder={"0.0"}
                        defaultValue=""
                        />
                    </div>
                </div>

                {/* add to cart button */}
              <Button
                variant="contained"
                size="small"
                color="primary"
                className="!px-6 !rounded-xl !normal-case !text-xl !w-full !h-[60px] !mt-4"
                type="submit"
              >
                Add to Cart
              </Button>

            </form>


          </div>
       
      </div>
    </>
  );
};

