import { useNavigate } from "react-router-dom";

type Props = {
  className?: string;
};
export default function AppWelcome({ className }: Props) {
  const navigate = useNavigate();
  return (
    <>
      <div className="w-full h-screen bg-white flex flex-col items-center justify-center sm:px-[30%] pt-10">
        {/* plant a tree for life */}
        <div className="w-full h-8 px-5">
          <div className="Header1 left-0 top-0  text-black text-2xl font-medium w-full">
            Plant a tree
            <br/>
            for life
          </div>
        </div>
        {/* image */}
        <div className="DisplayImage w-48 h-auto flex flex-col items-center justify-end">
          <img
            className="Image011 w-[172px] h-[364px]"
            src="/assets/images/img_image011.png"
            alt="Home"
          />
          <div className="w-32 h-2.5 bg-black bg-opacity-30 rounded-full" />
        </div>
        {/* worldwide deliver */}
        <div className="Header2 w-full mt-3">
          <div className="w-full px-20 top-0  text-center text-zinc-800 text-2xl font-normal">
            Worldwide deliver within 21 days
          </div>
        </div>
        {/* go button */}
        <div className="mt-3">
          <button 
            onClick={() => navigate("/app/login")}
          className="w-20 h-20 bg-black hover:bg-gray-600 text-white text-center rounded-full text-2xl font-bold">Go</button>
        </div>
      </div>
    </>
  );
}
