import useDocumentTitle from "../hooks/userDocumentTitle";
import MainTemplate from "./MainTemplate";

export default function BackOrders() {
    useDocumentTitle("Back Orders");
    return (<>
    <MainTemplate title="Back Orders">
        
    </MainTemplate>
    </>);
}