import * as FileSaver from "file-saver";
// import { useContext } from "react";
import * as XLSX from 'xlsx/xlsx.mjs';
// import AppContext from "./AppContext";
// import { helpers } from "./helpers";

const ExportExcelButton = ({
  children,
  excelData,
  fileName,
  className = "",
}) => {


  const onExport = () => {
    exportToExcel(excelData, fileName);
  }



  return (
    <button onClick={onExport} type="button" className={className}>
      {children}
    </button>
  );
};

const exportToExcel = async (excelData, fileName) => {
  const ws = XLSX.utils.json_to_sheet(excelData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
  FileSaver.saveAs(data, fileName + ".xlsx");
};

export { ExportExcelButton, exportToExcel };