// import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import useToken from "../hooks/useToken";
import AppContext from "../AppContext";
import { useContext } from "react";
import Loader from "../components/Loader";

function ProtectedApp() {
  const { token } = useToken();
  const { loading, user }: any = useContext(AppContext);

  if (!token) {
    // user is not authenticated
    return <Navigate to="/app/welcome" />;
  }

  if (loading) {
    return <Loader />;
  } else {
    if (!user) {
      //user profile cannot be fetch - probabaly authentication error
      // return <h1>No user</h1>;
      return <Navigate to="/app/welcome" />;
    } else {
      if (
        [
          "admin",
          "super_admin",
          "shop_manager",
          "production_manager",
          "accounting_manager",
          "delivery_manager",
        ].includes(user.role)
      ) {
        return <Navigate to="/admin" />;
      } else if (["inventory_manager"].includes(user.role)) {
        return <Navigate to="/admin/products" />;
      }
    }
  }

  return <Outlet />; //to display the inner children routes
}

export default ProtectedApp;
