export default function FreeShippingAds() {
  return (
    <>
      <div className="w-full h-32 relative">
        <div className="Banner w-full h-32 left-0 top-0 absolute">
          <div className="Rectangle4 w-full h-24 left-0 top-[30.33px] absolute bg-neutral-400 rounded-2xl" />
          <img
            className="Saly3 w-32 h-32 left-[217.87px] top-0 absolute"
            src="/assets/images/img_saly3.png"
            alt="ads"
          />
        </div>
        <div className="BodyText3 w-[200px] h-5 left-[28px] top-[55px] absolute">
          <div className="BodyText1 left-0 top-0 absolute text-white text-base font-medium leading-tight">
            Free shipping
          </div>
        </div>
        <div className="BodyText4 w-[100px] h-4 left-[28px] top-[89px] absolute">
          <div className="Caption w-[100px] h-2.5 left-0 top-0 absolute text-white text-xs font-light leading-3">
            When ordering
          </div>
        </div>
        <div className="Frame81 px-2.5 py-1 left-[120px] top-[82px] absolute bg-white rounded-3xl shadow border border-white justify-center items-center gap-2.5 inline-flex">
          <div className="AddButton text-center text-black  text-base font-medium leading-tight">
            from N5M
          </div>
        </div>
      </div>
    </>
  );
}
