import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyCv50zKYj8zXBATOxqsWh-iJ1aUyfKp5sQ",
  authDomain: "dipsy-9b03d.firebaseapp.com",
  projectId: "dipsy-9b03d",
  storageBucket: "dipsy-9b03d.appspot.com",
  messagingSenderId: "646033380947",
  appId: "1:646033380947:web:3b77a543d59c36c376d8a6",
  measurementId: "G-YGQ7DW0BF2",
  fcmServerKey: "AAAAlmqXFlM:APA91bG3DvS04l2tNWJo-O9pTexJCn7OljI8dMAAnoXl0pdJ6pPTjh1H03DzXi8jTzOoHcNORMDfARYeM9gQ8GqfGsCoVHGaiaexrelknxG9JYoAFfI6tM-RMHksJV465L-iFtZmW9wY",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);
export const storage = getStorage(app);



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
