/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

export const useViewport = (mobileBreakpoint = 768) => {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [isMobile, setIsMobile] = useState(false);
    const [isTablet, setIsTablet] = useState(false);

    useEffect(() => {
        const handleWindowResize = () => {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
            setIsMobile(window.innerWidth <= mobileBreakpoint);
            setIsTablet(window.innerWidth > mobileBreakpoint && window.innerWidth <= 1024);
        };
        handleWindowResize();
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return { width, height, isMobile, isTablet };
}