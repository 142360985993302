import { Card, CardActionArea } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useToken from "../../hooks/useToken";

export default function AppMore() {
  const navigate = useNavigate();

  const { logout } = useToken();

  const logOut = () => {
    logout();
    window.location.href = "/app/login";
  };

  return (
    <>
      <header className="z-30 fixed top-0 left-0 w-full py-2 bg-white shadow-sm dark:bg-gray-800">
        <div className="container flex items-center justify-between h-full px-5 mx-auto text-purple-600 dark:text-purple-300">
          {/* Page title */}
          <p className="text-xl font-semibold tracking-wide text-gray-800 text-center w-full my-2">
            More
          </p>
        </div>
      </header>

      <div className="mt-[80px]"></div>
      {/* page */}
      <div className="space-y-2 px-7 sm:px-80 flex flex-col items-center justify-center h-[calc(100vh-200px)]">
        {/* analysis */}
        <Card
          sx={{ maxWidth: "100%" }}
          className="!rounded-0 !shadow-sm !border !border-gray-100 !w-full"
          onClick={() => {
            navigate("/app/analysis");
          }}
        >
          <CardActionArea>
            <div className="p-3 flex items-center justify-center text-xl text-gray-500">
              Analysis
            </div>
          </CardActionArea>
        </Card>
        {/* profile */}
        <Card
          sx={{ maxWidth: "100%" }}
          className="!rounded-0 !shadow-sm !border !border-gray-100 !w-full"
          onClick={() => {
            navigate("/app/profile");
          }}
        >
          <CardActionArea>
            <div className="p-3 flex items-center justify-center text-xl text-gray-500">
              Profile
            </div>
          </CardActionArea>
        </Card>
        {/* contact factory */}
        <Card
          sx={{ maxWidth: "100%" }}
          className="!rounded-0 !shadow-sm !border !border-gray-100 !w-full"
          onClick={() => {
            window.open("sms:+2348165849739", "_self");
          }}
        >
          <CardActionArea>
            <div className="p-3 flex items-center justify-center text-xl text-gray-500">
              Contact Factory
            </div>
          </CardActionArea>
        </Card>
        {/* logout */}
        <Card
          sx={{ maxWidth: "100%" }}
          className="!rounded-0 !shadow-sm !border !border-gray-100 !w-full"
          onClick={() => {
            logOut();
          }}
        >
          <CardActionArea>
            <div className="p-3 flex items-center justify-center text-xl text-gray-500">
              Logout
            </div>
          </CardActionArea>
        </Card>
      </div>
    </>
  );
}
