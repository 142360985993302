
// import { useContext } from "react";
import {Navigate, Outlet } from "react-router-dom";
import useToken from "../hooks/useToken";
import AppContext from "../AppContext";
import Loader from "./Loader";
import { useContext } from "react";

function ProtectedLayout() {
    const {token} = useToken();
    const {loading, user }: any = useContext(AppContext);
    

    if (!token) {
        // user is not authenticated
        return <Navigate to="/admin/login" />;
    }




    if (loading) {
      return <Loader/>
    } else {
      if (!user) {
        //user profile cannot be fetch - probabaly authentication error
        // return <h1>No user</h1>;
        return <Navigate to="/admin/login" />;
      }
    }

    return <Outlet />; //to display the inner children routes
}

export default ProtectedLayout;