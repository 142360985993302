/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";


type Props = {
    attribute: any;
    index: number;
    handleAttributeChange: (index: number, attribute: any) => void;
    handleAttributeDelete: (index: number) => void;
}
const ProductEditAttributeBox = ({ attribute, index, handleAttributeChange, handleAttributeDelete }: Props) => {
    const [attributeData, setAttributeData] = useState(attribute ?? {});
    const [options, setOptions] = useState(attribute?.options ?? []);
    const [dbOptions, setDbOptions] = useState(attribute?.db_options ?? []);

    useEffect(() => {
        setAttributeData(attribute);
        setOptions(attribute.options ?? []);
        setDbOptions(attribute.db_options ?? []);
    }, [attribute, attributeData.options, attributeData.db_options]);

    useEffect(() => {
        handleAttributeChange(index, attributeData);
    }, [attributeData]);

    useEffect(() => {
        handleAttributeChange(index, { ...attributeData, options });
    }, [options]);


    // const onInputChanged = (e: any) => {
    //     setAttributeData({ ...attributeData, [e.target.name]: e.target.value });
    // }

    return (
        <div className="flex flex-row justify-between items-center mb-2 gap-x-2">
            <div className="flex flex-row gap-x-2 w-full">
                <div className="mt-2 lg:min-w-[80px]">
                    <label htmlFor="name" className="sr-only">Name</label>
                    <h3 className="text-sm font-medium text-gray-900">{attributeData.name}</h3>
                </div>
                <div className="w-full">
                    <label htmlFor="options" className="sr-only">Value(s):</label>
                    <MultiSelect
                        options={dbOptions}
                        value={options}
                        onChange={setOptions}
                        labelledBy="Select"
                        className="w-full"
                        // placeHolder="Select terms"
                    />
                </div>
            </div>
            <button
                type="button"
                onClick={() => handleAttributeDelete(index)}
                className="text-red-500 hover:text-red-700"
            >
                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.7071 7.29289C13.0976 7.68342 13.0976 8.31658 12.7071 8.70711L8.41421 13L12.7071 17.2929C13.0976 17.6834 13.0976 18.3166 12.7071 18.7071C12.3166 19.0976 11.6834 19.0976 11.2929 18.7071L7 14.4142L2.70711 18.7071C2.31658 19.0976 1.68342 19.0976 1.29289 18.7071C0.902369 18.3166 0.902369 17.6834 1.29289 17.2929L5.58579 13L1.29289 8.70711C0.902369 8.31658 0.902369 7.68342 1.29289 7.29289C1.68342 6.90237 2.31658 6.90237 2.70711 7.29289L7 11.5858L11.2929 7.29289C11.6834 6.90237 12.3166 6.90237 12.7071 7.29289Z" />
                </svg>
            </button>
        </div>
    );
}

export default ProductEditAttributeBox;