import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { ArrowBackIos } from "@mui/icons-material";

export default function AppAnalysis() {
  const navigate = useNavigate();

  return (
    <>
      {/* header */}
      <header className="z-30 fixed top-0 left-0 w-full py-2 bg-white shadow-sm dark:bg-gray-800">
        <div className="container flex items-center justify-between h-full px-5 mx-auto text-purple-600 dark:text-purple-300">
          {/* back button */}
          <ul className="flex items-center flex-shrink-0 space-x-6">
            <li className="relative">
              <IconButton
                aria-label="cart"
                color="primary"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ArrowBackIos />
              </IconButton>
            </li>
          </ul>
          {/* Page title */}
          <p className="text-xl font-semibold tracking-wide text-gray-800 text-center w-full">
            Analysis
          </p>
        </div>
      </header>

      <div className="mt-[90px]"></div>

      <div className="px-4 sm:px-40">
        {/* no analysis */}
        <div className="flex flex-col items-center justify-center gap-y-2 mt-[150px] mb-[100px]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-[90px] h-[90px] text-gray-400"
          >
            <path d="M18.375 2.25c-1.035 0-1.875.84-1.875 1.875v15.75c0 1.035.84 1.875 1.875 1.875h.75c1.035 0 1.875-.84 1.875-1.875V4.125c0-1.036-.84-1.875-1.875-1.875h-.75ZM9.75 8.625c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-.75a1.875 1.875 0 0 1-1.875-1.875V8.625ZM3 13.125c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v6.75c0 1.035-.84 1.875-1.875 1.875h-.75A1.875 1.875 0 0 1 3 19.875v-6.75Z" />
          </svg>

          <div className="text-lg text-black font-normal">
            No genuine data available yet
          </div>
        </div>
        {/* analysis */}
      </div>

      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
}
