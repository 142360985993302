import { useEffect, useState } from "react";
import { helpers } from "../../helpers";



type ProductPageImageSliderProps = {
    product: any,
};
export default function ProductPageImageSlider({product}: ProductPageImageSliderProps) {

    const [slideIndex, setSlideIndex] = useState(0);
      // animate slides
  useEffect(() => {
    const interval = setInterval(() => {
      if (slideIndex === product?.images?.length - 1) {
        setSlideIndex(0);
      } else {
        setSlideIndex(slideIndex + 1);
      }
    }, 3000);
    return () => clearInterval(interval);
  }, [slideIndex, product?.images?.length]);

    return (
        <div className="bg-gray-400 h-[350px] sm:h-auto sm:min-h-[400px] rounded-bl-3xl rounded-br-3xl sm:rounded-3xl relative overflow-clip">
            {product?.images?.map((image: any) => (
              <img
                key={image}
                src={helpers.getFirstImage(image)}
                alt=""
                className={`w-full h-full object-cover  -translate-x-1/2 -translate-y-1/2 absolute top-1/2 left-1/2 duration-700 ease-in-out 
            ${
              slideIndex === product.images.indexOf(image)
                ? "opacity-100"
                : "opacity-0"
            }`}
              />
            ))}
            {/* indicators */}
            <div className="absolute bottom-0 left-0 w-full flex justify-center gap-2 pb-2">
              {product?.images?.map((image: any) => (
                <div
                  key={image}
                  className={`w-12 h-2 rounded-full ${
                    slideIndex === product.images.indexOf(image)
                      ? "bg-gray-100"
                      : "bg-gray-400"
                  }`}
                ></div>
              ))}
            </div>
          </div>
    );
}