/* eslint-disable react-hooks/exhaustive-deps */
'use client';
import useDocumentTitle from "../hooks/userDocumentTitle";
import logo from "../assets/images/logo.png"


const EmailVerification = () => {
    useDocumentTitle('Email Verification');

    return (
        <>
            <div className="h-screen w-full bg-[#f7fafc] px-5">
                <div className="flex flex-col items-center justify-center min-h-screen">
                    <div className="w-full lg:w-2/3 lg:w-1/2 lg:w-1/3">
                        <img src={logo} alt="Logo" className="mx-auto mb-6 w-24 lg:w-32"/>
                            <div className="bg-white shadow-md p-6 rounded-lg">
                                <h1 className="text-2xl lg:text-3xl font-bold text-center mb-4">Email Verified</h1>
                                <p className="text-center text-gray-600">Thank you for verifying your email address.</p>
                                <p className="text-center text-gray-600">You can now contnue using our app.</p>
                            </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EmailVerification;