
import useDocumentTitle from "../hooks/userDocumentTitle";
import forgotPasswordImage from "../assets/images/forgot-password-office.jpeg"


const ForgotPassword = () => {
    useDocumentTitle('Forgot Password');

  
    return (
        <>
             <div className="flex items-center min-h-screen p-6 bg-gray-50 dark:bg-gray-900">
      <div
        className="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800"
      >
        <div className="flex flex-col overflow-y-auto lg:flex-row">
          <div className="h-32 lg:h-auto lg:w-1/2">
            <img
              aria-hidden="true"
              className="object-cover w-full h-full dark:hidden"
              src={forgotPasswordImage}
              alt="Office"
            />
            <img
              aria-hidden="true"
              className="hidden object-cover w-full h-full dark:block"
              src={forgotPasswordImage}
              alt="Office"
            />
          </div>
          <div className="flex items-center justify-center p-6 lg:p-12 lg:w-1/2">
            <div className="w-full">
              <h1
                className="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200"
              >
                Forgot password
              </h1>
              <label className="block text-sm">
                <span className="text-gray-700 dark:text-gray-400">Email</span>
                <input
                  className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="johndoe@example.com"
                />
              </label>

              {/* <!-- You should use a button here, as the anchor is only used for the example  --> */}
              <button
                className="block w-full px-4 py-2 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg active:bg-purple-600 hover:bg-purple-700 focus:outline-none focus:shadow-outline-purple"
              
              >
                Recover password
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
        </>
    );
}

export default ForgotPassword;