import * as React from "react";
import Chip from "@mui/material/Chip";

type Props = {
  category: any;
  active?: boolean;
  onTap?: (category: any) => void;
};
export default function CategoryLabelCard({
  category,
  onTap,
  active = false,
}: Props) {
  return active ? (
        <Chip
          label={category.name}
          color="primary"
          onClick={() => {
            onTap && onTap(category);
          }}
        />
      ) : (
        <Chip
          label={category.name}
          variant="outlined"
          onClick={() => {
            onTap && onTap(category);
          }}
        />
      );
}
